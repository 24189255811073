import React from "react"
import { Link, useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import { Tab, Tabs, TabList, TabPanel } from "react-tabs"

import Layout from "../components/Layout"
import SEO from "../components/seo"
import HeroHeaderImage from "../components/HeroHeaderImage"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faLayerGroup,
  faShoppingBag,
  faBullseye,
  faCopy,
  faBookmark,
} from "@fortawesome/free-solid-svg-icons"

const LojaPage = () => {
  const {
    lojaSegura,
    lojaBusca,
    lojaFlexivel,
    lojaDesign,
    lojaVendas,
    lojaMarketing,
    lojaRelatorios,
    lojaCategorias,
    lojaDesenvolvimento,
  } = useStaticQuery(graphql`
    query {
      lojaSegura: file(
        relativePath: { eq: "layout/icones/seguranca-192.jpg" }
      ) {
        id
        childImageSharp {
          fluid(maxWidth: 192, maxHeight: 192, quality: 72) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      lojaBusca: file(relativePath: { eq: "layout/icones/buscas-192.jpg" }) {
        id
        childImageSharp {
          fluid(maxWidth: 192, maxHeight: 192, quality: 72) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      lojaFlexivel: file(
        relativePath: { eq: "layout/icones/poderosa-192.jpg" }
      ) {
        id
        childImageSharp {
          fluid(maxWidth: 192, maxHeight: 192, quality: 72) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      lojaDesign: file(relativePath: { eq: "layout/loja/loja-design.jpg" }) {
        id
        childImageSharp {
          fluid(maxWidth: 427, maxHeight: 320, quality: 92) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      lojaVendas: file(relativePath: { eq: "layout/loja/loja-pedidos.jpg" }) {
        id
        childImageSharp {
          fluid(maxWidth: 427, maxHeight: 320, quality: 92) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      lojaMarketing: file(
        relativePath: { eq: "layout/loja/loja-marketing.jpg" }
      ) {
        id
        childImageSharp {
          fluid(maxWidth: 427, maxHeight: 320, quality: 92) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      lojaRelatorios: file(
        relativePath: { eq: "layout/loja/loja-relatorios.jpg" }
      ) {
        id
        childImageSharp {
          fluid(maxWidth: 427, maxHeight: 320, quality: 92) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      lojaCategorias: file(
        relativePath: { eq: "layout/loja/loja-categorias.jpg" }
      ) {
        id
        childImageSharp {
          fluid(maxWidth: 427, maxHeight: 320, quality: 92) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      lojaDesenvolvimento: file(relativePath: { eq: "layout/portfolio.png" }) {
        id
        childImageSharp {
          fixed(pngQuality: 100, width: 318, height: 182) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)

  return (
    <Layout>
      <SEO title="Loja Virtual" />
      <HeroHeaderImage
        heroMode={"is-dark"}
        heroSize={"is-small"}
        heroClass={"loja"}
        navActive={"loja"}
      >
        <div className="container">
          <div className="block is-titulo-hero">
            <div className="columns">
              <div className="column is-6">
                <h1 className="title is-3 is-uppercase">
                  Comece agora sua Loja Virtual
                </h1>
                <h2 className="subtitle is-5">
                  Com uma plataforma completa para o mercado brasileiro
                </h2>
                <p>
                  Oferecemos uma plataforma moderna para o comércio eletrônico
                  com uma imensa variedade de recursos, um conjunto de
                  ferramentas ideal para qualquer tamanho de negócio. Com mais
                  de <strong>10 anos de experiência</strong> no mercado online,
                  colocamos a disposição tudo para a sua loja virtual operar no
                  mercado brasileiro.
                </p>
                <p className="m-t-md">
                  Também oferecemos serviços de customização completa da
                  plataforma. Ajustamos cada necessidade e especificação do seu
                  negócio.
                </p>
                <p className="m-t-md">
                  Entre em contato com a gente, faça um orçamento sem
                  compromisso!
                </p>
                <Link
                  to="/contato"
                  className="button is-medium is-light is-outlined m-t-md"
                >
                  Fazer um orçamento
                </Link>
              </div>
            </div>
          </div>
        </div>
      </HeroHeaderImage>

      <section className="container m-b-lg">
        <div className="block has-text-centered">
          <h1 className="title is-3 has-text-centered p-lg">
            Principais Recursos da Plataforma
          </h1>

          <div className="columns" id="vantagens">
            <div className="column">
              <figure className="image is-96x96 is-inline-block">
                <Img
                  fluid={lojaSegura.childImageSharp.fluid}
                  alt="Loja Segura"
                />
              </figure>
              <h1 className="title is-5 is-marginless">Segura</h1>
              <p>
                Plataforma atualizada constantemente para manter sua loja
                virtual segura. Informações do cadastro de clientes, fechamento
                de pedidos e da administração da loja são todas criptografadas
                com o seu <Link to="/contato">Certificado Digital SSL</Link>.
              </p>
            </div>
            <div className="column">
              <figure className="image is-96x96 is-inline-block">
                <Img
                  fluid={lojaBusca.childImageSharp.fluid}
                  alt="Adaptada para Buscas"
                />
              </figure>
              <h1 className="title is-5 is-marginless">Adaptada para Buscas</h1>
              <p>
                Plataforma totalmente adaptada para SEO (Search Engine
                Optimization), URL Amigáveis e Google Sitemaps. Tudo o que você
                precisa para ajudar a colocar sua loja virtual nas melhores
                colocações nos mecanismos de buscas.
              </p>
            </div>
            <div className="column">
              <figure className="image is-96x96 is-inline-block">
                <Img
                  fluid={lojaFlexivel.childImageSharp.fluid}
                  alt="Poderosa e Flexível"
                />
              </figure>
              <h1 className="title is-5 is-marginless">Poderosa e Flexível</h1>
              <p>
                Plataforma robusta que oferece a possibilidade de implementar na
                sua loja virtual módulos de ajuste diferenciado ou específicos
                do seu negócio.
              </p>
              <p>
                Conheça os serviços de desenvolvimento da <strong>SH2</strong>:{" "}
                <Link to="/contato">
                  Criação de Layout, Integração e Suporte
                </Link>
                .
              </p>
            </div>
          </div>
        </div>

        <div className="block m-t-xl">
          <Tabs selectedTabClassName="is-active">
            <div className="tabs is-centered is-medium is-fullwidth">
              <TabList>
                <Tab className="tab">
                  <div>
                    <FontAwesomeIcon
                      icon={faLayerGroup}
                      className="icon is-small"
                    />
                    <span>Design</span>
                  </div>
                </Tab>
                <Tab>
                  <div>
                    <FontAwesomeIcon
                      icon={faShoppingBag}
                      className="icon is-small"
                    />
                    <span>Vendas</span>
                  </div>
                </Tab>
                <Tab>
                  <div>
                    <FontAwesomeIcon
                      icon={faBullseye}
                      className="icon is-small"
                    />
                    <span>Marketing</span>
                  </div>
                </Tab>
                <Tab>
                  <div>
                    <FontAwesomeIcon icon={faCopy} className="icon is-small" />
                    <span>Relatórios</span>
                  </div>
                </Tab>
                <Tab>
                  <div>
                    <FontAwesomeIcon
                      icon={faBookmark}
                      className="icon is-small"
                    />
                    <span>Gestão de Produtos</span>
                  </div>
                </Tab>
              </TabList>
            </div>
            <div className="container p-sm tabs-loja">
              <TabPanel className="tab-content">
                <div className="columns">
                  <div className="column">
                    <figure className="image">
                      <Img
                        fluid={lojaDesign.childImageSharp.fluid}
                        alt="Customização de layout"
                      />
                    </figure>
                  </div>
                  <div className="column">
                    <h1 className="title is-6">Customização de layout</h1>
                    <p>
                      Personalize sua loja. Insira seu logotipo, escolha as
                      cores bases e de destaque, layout de exibição dos
                      produtos, fontes e muito mais.
                    </p>
                  </div>
                  <div className="column">
                    <h1 className="title is-6">Blocos</h1>
                    <p>
                      Sistema completo de configuração de páginas baseado em
                      blocos. Monte a estrutura das suas páginas da forma que
                      desejar, defina tamanho e quais blocos inserir em cada
                      área.
                    </p>
                    <p>
                      Os blocos ainda permitem a inserção de{" "}
                      <strong>HTML Customizado</strong>, insira recursos
                      diversos da forma que desejar!
                    </p>
                  </div>
                </div>
              </TabPanel>

              <TabPanel className="tab-content">
                <div className="columns">
                  <div className="column">
                    <figure className="image">
                      <Img
                        fluid={lojaVendas.childImageSharp.fluid}
                        alt="Gestão Pedidos"
                      />
                    </figure>
                  </div>
                  <div className="column">
                    <h1 className="title is-6">Gestão de clientes</h1>
                    <p>
                      Administração completa do cadastro dos clientes da sua
                      loja virtual. Inserção, atualização de informações,
                      controle manual.
                    </p>
                    <p>
                      Sistema de buscas avançado para facilitar suas tarefas.
                      Encontre os clientes através do Nome, E-mail, CPF/CNPJ,
                      Data de Nascimento e mais.
                    </p>
                  </div>
                  <div className="column">
                    <h1 className="title is-6">Gestão de pedidos</h1>
                    <p>
                      Consulta rápida e controle total dos pedidos feitos em sua
                      loja. Consulte através do Código do Pedido, Comprador,
                      Status do Pedido (aguardando confirmação de pagamento, não
                      finalizado, finalizados, etc), Forma de Pagamento e mais.
                    </p>
                  </div>
                </div>
              </TabPanel>

              <TabPanel className="tab-content">
                <div className="columns">
                  <div className="column">
                    <figure className="image">
                      <Img
                        fluid={lojaMarketing.childImageSharp.fluid}
                        alt="Cupons de desconto"
                      />
                    </figure>
                  </div>
                  <div className="column">
                    <h1 className="title is-6">Cupons de desconto</h1>
                    <p>
                      Crie e gerencie os cupons de desconto da sua loja virtual.
                      Sistema com regras amplas para fazer sua promoção da forma
                      que desejar.
                    </p>
                  </div>
                  <div className="column">
                    <h1 className="title is-6">Desconto exclusivo</h1>
                    <p>
                      Ideal para campanhas exclusivas. Ofereça descontos para
                      categorias ou produtos específicos.
                    </p>
                    <h1 className="title is-6  m-t-sm">
                      Lista de mail marketing
                    </h1>
                    <p>
                      Sistema nativo para criar sua lista de clientes para envio
                      de mail marketing. Cadastro feito pela loja para você
                      exportar para a ferramenta de envio que desejar.
                    </p>
                  </div>
                </div>
              </TabPanel>

              <TabPanel className="tab-content">
                <div className="columns">
                  <div className="column">
                    <figure className="image">
                      <Img
                        fluid={lojaRelatorios.childImageSharp.fluid}
                        alt="Estatísticas gerais"
                      />
                    </figure>
                  </div>
                  <div className="column">
                    <h1 className="title is-6">Painel inicial</h1>
                    <p>
                      Painel inicial com todas as informações principais que
                      você precisa: pedidos recentes (com opção de filtro por
                      status), total de vendas do dia, total de vendas no mês,
                      produtos ativos, produtos sem estoque e mais.
                    </p>
                  </div>
                  <div className="column">
                    <h1 className="title is-6">Estatisticas gerais</h1>
                    <p>
                      Estatísticas de vendas com gráficos. Relatório com o valor
                      vendido por cada produto, por categoria, localidades mais
                      ativas, maiores clientes, custo de envio por pedido e
                      formas de pagamento mais usadas.
                    </p>
                  </div>
                </div>
              </TabPanel>

              <TabPanel className="tab-content">
                <div className="columns">
                  <div className="column">
                    <figure className="image">
                      <Img
                        fluid={lojaCategorias.childImageSharp.fluid}
                        alt="Produtos e categorias"
                      />
                    </figure>
                  </div>
                  <div className="column">
                    <h1 className="title is-6">Produtos e categorias</h1>
                    <p>
                      Cadastro rápido ou avançado de produtos e categorias de
                      forma eficiente. A plataforma oferece inserção de
                      características específicas, sistema nativo de variações,
                      abas de informações e filtros adicionais de busca.
                    </p>
                    <p>
                      Crie páginas personalizadas de categorias ou de um
                      produto.
                    </p>
                  </div>
                  <div className="column">
                    <h1 className="title is-6">
                      Importação de produtos em lote
                    </h1>
                    <p>Faça o cadastro de vários produtos de uma só vez.</p>
                    <h1 className="title is-6 m-t-sm">Gestão de marcas</h1>
                    <p>
                      Atribua marcas aos produtos e a loja criará filtros
                      inteligentes para exibir categorias automáticas de
                      produtos de uma mesma marca.
                    </p>
                  </div>
                </div>
              </TabPanel>
            </div>
          </Tabs>
        </div>
      </section>

      <section className="hero is-small is-dark is-bold p-md">
        <div className="hero-body">
          <div className="container">
            <div className="columns">
              <div className="column is-two-thirds">
                <h1 className="title is-4 is-uppercase">
                  Para diversos tamanhos de negócio
                </h1>
                <p className="subtitle p-t-md">
                  A plataforma que ofereçemos é completa para todos os estágios
                  do seu negócio. Ela atende desde o vendedor com uma Loja Única
                  até sistemas Multi-Vendor e Marketplaces.
                </p>
                <p className="subtitle">
                  Tenha segurança para o seu negócio crescer, começe a vender
                  pela internet hoje.
                </p>
              </div>
              <div className="column is-vertical-center has-text-centered">
                <Img
                  fixed={lojaDesenvolvimento.childImageSharp.fixed}
                  alt="Desenvolvimento de lojas e sites"
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="container m-b-lg">
        <div className="block">
          <h1 className="title is-3 has-text-centered p-lg">Nossos Serviços</h1>
          <div className="columns">
            <div className="column">
              <div className="card bm-card-equal-height">
                <div className="card-content">
                  <p className="title is-4">Criação de Layout</p>
                  <p>
                    A <strong>SH2</strong> oferece customização completa da
                    interface da sua loja. Colocamos sua idéia de layout para
                    público e segmento específico em prática!
                  </p>
                  <p className="m-t-md">
                    Nosso time traz sugestões de como melhor aproveitar a sua
                    loja usando design responsivo, para que ela fique disponível
                    em todas as dimensões de tela com um único layout.{" "}
                    <Link to="/contato">Consulte.</Link>
                  </p>
                </div>
              </div>
            </div>

            <div className="column">
              <div className="card bm-card-equal-height">
                <div className="card-content">
                  <p className="title is-4">Integrações</p>
                  <p>
                    A <strong>SH2</strong> possui integrações com diversos meios
                    de pagamentos, sistemas de gestão, marketplaces, ERPs e
                    outros fornecedores de soluções para sua loja online.
                  </p>
                  <p className="m-t-md">
                    Precisa de uma integração específica? A <strong>SH2</strong>{" "}
                    analisa a viabilidade do seu caso e desenvolve a integração.{" "}
                    <Link to="/contato">Consulte.</Link>
                  </p>
                </div>
              </div>
            </div>

            <div className="column">
              <div className="card bm-card-equal-height">
                <div className="card-content">
                  <p className="title is-4">Suporte Técnico</p>
                  <p>
                    Receba atendimento sem demora. Tanto por telefone quanto por
                    chamados via e-mail, com resposta rápida a sua solicitação.
                  </p>
                  <p className="m-t-md">
                    A <strong>SH2</strong> também oferece pacotes personalizados
                    a necessidade de sua empresa.{" "}
                    <Link to="/contato">Consulte.</Link>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default LojaPage
